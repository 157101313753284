// react imports
import React, { useState, useRef, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';

import video_play from '../../assets/media/icons/Group 3.png'
import prev from '../../assets/media/icons/prev-green.png'
import next_button from '../../assets/media/icons/next-1.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled-green.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty-green.png'
// CUSTOM COMPONENTS
import { MDBContainer
          } from 'mdb-react-ui-kit';

const CarouselComponentTop = (props) => {
  // REACT ROUTER PARAMS - for processing url
 
  const [starttime, setStarttime] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true)
  //const [videocardHeight, setVideocardHeight] = useState(document.getElementById("thumbnail-image-allo")?.clientWidth*16/9 - 30);

  // react router navigation
  const navigate = useNavigate();

 /* const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideocardHeight(document.getElementById("thumbnail-image-allo")?.clientWidth*16/9 - 30)
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setTimeout(() => {setStarttime(true) }, 300)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setVideocardHeight(document.getElementById("thumbnail-image-allo")?.clientWidth*16/9 - 30)
  }, [document.getElementById("thumbnail-image-allo")?.clientWidth]);*/

  useEffect(() => {
    setTimeout(() => {setStarttime(true) }, 300)
  }, []);

  const responsive = {
    desktop2: {
      breakpoint: { max: 4000, min: 1920 },
      items: 4,
      partialVisibilityGutter: 3
    },
    desktop: {
      breakpoint: { max: 1920, min: 1400 },
      items: 4,
      partialVisibilityGutter: 3
    },
    tablet2: {
      breakpoint: { max: 1400, min: 1100 },
      items: 3,
      partialVisibilityGutter: 3
    },
    tablet: {
      breakpoint: { max: 1100, min: 740 },
      items: 3,
      partialVisibilityGutter: 3
    },
    small: {
        breakpoint: { max: 450, min: 0 },
        items: 2,
        partialVisibilityGutter: 1
    }
  };

  const truncateString = (str, num) => {
    if (str !== undefined && str.length <= num) {
        return str
      }
          return str.slice(0, num) + '...'
  }

  const swiper = new Swiper('.swiper-top', {
    // Optional parameters
    modules: [FreeMode],
    //slidesPerView: 2,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      // when window width is >= 480px
      600: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      // when window width is >= 640px
      760: {
        slidesPerView: 4,
        spaceBetween: 0
      }
    },
    direction: 'horizontal',
    loop: false,
    effect: 'slide',
    freeMode: {
      enabled: true,
      sticky: true,
    },
  });
  

  const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { totalItems, currentSlide } = carouselState;
    return (
        <MDBContainer style={{maxWidth: window.innerWidth > 1920 ? 1920 : window.innerWidth}} className="button-container">
          <div className="custom-button-group">
            <button className={isFirstStep ? "left-button firststep" : "left-button"} onClick={() => previous()}><img src={prev} alt="prev"/></button>
            <button className="right-button" onClick={() => next()}><img src={next_button} alt="next"/></button>
          </div>
        </MDBContainer>
    );
  };

  return (
    <MDBContainer style={{maxWidth: window.innerWidth > 1599.99 ? window.innerWidth > 1920 ? 1557 : window.innerWidth - 363 : window.innerWidth > 1080 ? window.innerWidth-220 : window.innerWidth-20, padding: 0, position: "relative", height: document.getElementById("thumbnail-image-allo")?.clientWidth*16/9 +90}} className="top-carousel">
      <div className="d-none d-lg-block">
        <Carousel
            additionalTransfrom={0} 
            swipeable={true}
            draggable={!window.matchMedia('(max-device-width: 740px)').matches ? false :  true}
            showDots={!window.matchMedia('(max-device-width: 740px)').matches ? true :  false}
            responsive={responsive}
            infinite={false}
            slidesToSlide={window.matchMedia('(max-device-width: 450px)').matches ? 9 :
                3
            }
            customTransition={window.matchMedia('(max-device-width: 45px)').matches ? "transform 10ms ease-in-out" : "transform 1000ms ease-in-out"}
            transitionDuration={window.matchMedia('(max-device-width: 450px)').matches ? 10 : 1000}
            renderArrowsWhenDisabled={true}
            renderDotsOutside={true}
            centerMode={false}
            keyBoardControl={true}
            containerClass={isFirstStep ? "carousel-container firststep" : "carousel-container"}
            removeArrowOnDeviceType={["small", "tablet"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            afterChange={(previousSlide, { currentSlide, onMove }) => {
                setIsFirstStep(false);
            }}
            renderButtonGroupOutside={true} 
            customButtonGroup={<CustomButtonGroup/>}
            >
            {props.videoList.map((k,n) => {
              let array = k.name.split(" ")
                return(
                    <div className="videocard" key={n} id="touchsurface"  style={{height: document.getElementById("thumbnail-image-allo")?.clientWidth*16/9 + 70}}>
                      <div className="number">
                        {n+1}
                      </div>
                      <div className="background-img">
                        <img src={k.pictureUrl} alt="thumbnail-image" id="thumbnail-image-allo"/>
                        <div className="description">
                            <span className="description-text">
                            {truncateString(k.name, 35)}
                            </span>
                        </div>
                        <div style={{position: "absolute", width: "100%", height: "25%",
                                     background:  "#ffffff",
                                     opacity: 0.86,
                                     bottom: "0px",
                                     borderRadius: "0 0 25px 25px"
                                    }}/>
                        <div className="description-hover">
                         
                            <button  className="play-button" type="button" onClick={() => { navigate('/videodetail/' + k.id, { state: { video: k} })}}><img src={video_play} alt="player"/></button>
                           
                            <div className="bookmark-div">
                              <div className="more-text">
                                  <span>{truncateString(k.name, 25)}</span>
                              </div>
                              <button className="bookmark-button" type="button" onClick={k.bookmarked ? 
                                                () => props.onDeleteVideoFromBookmark(k.id) : () => props.onPutVideoToBookmark(k.id)}>
                                <img src={k.bookmarked ? bookmark_filled : bookmark_empty} alt="bookmark" />
                            </button> 
                          </div>
                        </div>
                      </div>
                    </div>
            )})}
        </Carousel>
      </div>
      <div className="swiper-top d-block d-lg-none">
        <div className="swiper-wrapper">
          {props.videoList.map((k,n) => {
            if(starttime){
              return(
                  <div className="swiper-slide" key={n}>
                  <div className="videocard-respo">
                  <div className="number">
                    <span>
                      {n+1}
                    </span>
                    
                  </div>
                  <div className="background-img" onClick={() => { navigate('/videodetail/' + k.id, { state: { video: k} })}}>
                    <img src={k.pictureUrl} alt="thumbnail-image"/>
                    <div style={{position: "absolute", width: "100%", height: "25%",
                                       background:  "#ffffff",
                                       opacity: 0.86,
                                       bottom: "0px",
                                       borderRadius: "0 0 10px 10px"
                                      }}/>
                    
                    <div className="more-text">
                        <span>{truncateString(k.name, 20)}</span>
                    </div>
                  </div>
                </div>
                  </div>
            )
            }
           })}
        </div>
      </div>
      
    </MDBContainer>
  );
};

export default CarouselComponentTop;

// react imports
import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player'


import video_play from '../../assets/media/icons/video-play.png'
import top_ten from '../../assets/media/icons/Top10.png'
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import CarouselComponent from '../carousel/CarouselComponent'
import CarouselComponentTop from '../carousel/CarouselComponentTop'
import search from '../../assets/media/icons/search.png'


import { MDBContainer,
         MDBLoadingManagement,
         MDBInput
          } from 'mdb-react-ui-kit';

const VideosPage = ({ isPageRender }) => {

  const context = useContext(AppContext);

  // react router navigation
  const navigate = useNavigate();
  const basicRef = useRef(null);
  

  // STATES
  const [categoryList, setCategoryList] = useState([])
  const [videoList, setVideoList] = useState([])
  const [newVideoList, setNewVideoList] = useState([])
  const [likeVideoList, setLikeVideoList] = useState([])
  const [playing, setPlaying] = useState(false)
  const randomNumber = Math.floor((Math.random() * 10))+1

  const onGetVideos = () => {
    context.interAxios.get('/video', function(response) {
        setVideoList(response.data.content)
        console.log(response.data.content)
        var set = new Set(response.data.content.map(k => {return (k.category)}))
        setCategoryList(Array.from(set).sort())
        
    });
  }

  const onGetNewVideos = () => {
    context.interAxios.get('/video/latest', function(response) {
        setNewVideoList(response.data)
    });
  }
  
  
  useEffect(() => { 
    if(videoList.length === 0){
      onGetVideos();
    }
    
    onGetNewVideos();
    context.interAxios.get('/bookmark', function(response) {
      context.onSetBookmarkList(response.data)
    });
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);


  useEffect(() => {
    context.interAxios.get('/like/most', function(response) {
      setLikeVideoList(response.data)
    });
  }, []);
 

  
  useEffect(() => {
    setTimeout(() => {
        setPlaying(true);
    }, 500)
  }, []);

  const onPutVideoToBookmark = (id) => {
    context.interAxios.put('/bookmark/' + id, function(response) {
        context.interAxios.get('/bookmark', function(response) {
          context.onSetBookmarkList(response.data)
        });
        onGetVideos();
        onGetNewVideos();
    });
  }

  const onDeleteVideoFromBookmark = (id) => {
      context.interAxios.delete('/bookmark/' + id, function(response) {
          context.interAxios.get('/bookmark', function(response) {
            context.onSetBookmarkList(response.data)
          });
          onGetVideos();
          onGetNewVideos();
      });
  }
  const truncateString = (str, num) => {
    
    if (str !== undefined && str.length <= num) {
        return str
    }
          return str.slice(0, num) + '...'
  }

  
  return (
    <MDBContainer style={{maxWidth: "1920px"}} className="landing-page">
      
        <section className="first-section  d-none d-lg-flex px-5" id="" style={{height: window.innerWidth < 1920 ? window.innerWidth*0.39375 : 756 +"px"}}>
          <div  className="column left" style={{height: document.getElementById("topvideo-div") ? document.getElementById("topvideo-div")?.clientWidth*9/16 : 100}}>
            <div className="bemutatkozik-text">
              <p>A közösségnek ereje van!</p>
              <span>Hiszek benne, hogy szükség van egy olyan cenzúrázatlan és fizetőfalak, reklámok nélküli, széleskörben elérhető tartalomra, amelyből emberek milliói tájékozódhatnak. Ez volt a célom az oldal megalkotásánál. Webfejlesztő informatikusként otthonunkban dolgozva megtehetem, hogy munkám közben is odafigyeljek és gondozzam súlyos fogyatékossággal élő családtagunkat.
              Ha saját magad részére, vagy ismerőseid körében bárkinek szüksége lehet hasonló tartalmak, vagy egyéb célú weboldalak készítésére, egy rövid ajánlással te is segíthetsz, hogy ezt a munkát tovább tudjam folytatni.
              </span>
            </div>
            <div className="input-div">
              <MDBInput label={<><img src={search} alt="search"/><span>Keresés</span></>} id="inputId" className="inputClass" type='text'  
                   />
              <button className="search-button" type="button" onClick={() => {navigate('/searchpage', {state: {searchText: document.getElementsByClassName("inputClass")[0].value}})}}>Keresés</button>
            </div>
          </div>
          <div  className="column right" id="topvideo-div" style={{maxWidth: window.innerWidth < 1920 ? window.innerWidth*0.7 : 1344, height: document.getElementById("topvideo-div") ? document.getElementById("topvideo-div")?.clientWidth*9/16 : 100, borderRadius: "45px"}}>
            {!playing ?  <> 
              <div ref={basicRef} className="loading-manager" style={{ zIndex: "3029", height: "100%" }}>
                  <MDBLoadingManagement  parentRef={basicRef} loadingText="Betöltés..."/>
              </div>
            </> : <>     
            <ReactPlayer url={newVideoList[0]?.fullUrl} 
                    width="100%"
                    height="100%" 
                    controls={false} 
                    volume={0.1}
                    muted={true}
                    playing={playing}
                    loop={true}
            />
            
            <div className="first-section-div">
              <span className="title">{newVideoList[randomNumber-2]?.name !== undefined ? truncateString(newVideoList[randomNumber-2]?.name, 50): ""}</span>
              <div className="top-video">
                <img src={top_ten} alt="top"/>
                <span>{"Top " + (randomNumber-1) + "th today"}</span>
              </div>
              {newVideoList[randomNumber-1]?.description && (
                <span className="description">
                  {newVideoList[randomNumber-2]?.description !== undefined ? truncateString(newVideoList[randomNumber-2]?.description, 70) : ""}
                </span>
              )}
              
              <button type="button" onClick={() => { navigate('/videoplay/' + newVideoList[randomNumber-2]?.id, { state: { fullUrl: newVideoList[randomNumber-2]?.fullUrl } })}}><img src={video_play} alt="top"/><span>PLAY</span></button>
            </div>
            </>}
          </div>
         
        </section>
        <section className="first-section  d-flex d-lg-none" id="" >
          <div  className="right-respo"  style={{maxWidth: window.innerWidth*0.9, height: window.innerWidth*0.9*0.5625, borderRadius: "45px"}}>
            {!playing ?  <> 
              <div ref={basicRef} className="loading-manager" style={{ zIndex: "3029", height: "100%" }}>
                  <MDBLoadingManagement  parentRef={basicRef} loadingText="Betöltés..."/>
              </div>
            </> : <>     
            <ReactPlayer url={newVideoList[0]?.fullUrl} 
                    width= {window.innerWidth*0.9} 
                    height={window.innerWidth*0.9*0.5625 +"px"} 
                    controls={false} 
                    volume={0.1}
                    muted={true}
                    playing={playing}
                    loop={true}
            />
            
            <div className="first-section-div">
              <span className="title">{newVideoList[randomNumber-2]?.name !== undefined ? truncateString(newVideoList[randomNumber-2]?.name, 50): ""}</span>
              <div className="top-video">
                <img src={top_ten} alt="top"/>
                <span>{"Top " + (randomNumber-1) + "th today"}</span>
              </div>
              {newVideoList[randomNumber-1]?.description && (
                <span className="description">
                  {newVideoList[randomNumber-2]?.description !== undefined ? truncateString(newVideoList[randomNumber-2]?.description, 70) : ""}
                </span>
              )}
              
              <button type="button" onClick={() => { navigate('/videoplay/' + newVideoList[randomNumber-2]?.id, { state: { fullUrl: newVideoList[randomNumber-2]?.fullUrl } })}}><img src={video_play} alt="top"/><span>PLAY</span></button>
            </div>
            </>}
          </div>
         
        </section>
        <section className="videotar">
          <div className="background-left" style={{marginLeft: window.innerWidth > 1920 ? -(window.innerWidth-1920)/2 : 0}}/>
          <div className="background-right"  style={{marginRight: window.innerWidth > 1920 ? -(window.innerWidth-1920)/2 : 0}}/>
          <div id="videotar" style={{overflow: "hidden"}}>
          
            {newVideoList.length > 0 && (
            <div style={{position: "relative", marginBottom: "10px"}} id={"carousel-0"}>
                <div className="category-title">Legújabb feltöltések</div>  
                <CarouselComponentTop videoList={newVideoList} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
            </div>)}

            {context.getState()?.bookmarkList?.length > 0 && (
              <div style={{position: "relative", marginTop: "20px", marginBottom: "20px"}} id={"carousel-a"}>
                  <div className="category-title" 
                  >Kedvenceim</div>  
                  <CarouselComponent category={"My favourites"} videoList={context.getState()?.bookmarkList} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
              </div>
            )}

  	        {categoryList.filter((k,n) => n < 4).map((k, n) => {return (
            <div key={n} style={{position: "relative", marginBottom: "20px"}} id={"carousel-" + n+1}>
              <button className="category-title d-none d-lg-flex" 
                      onClick={() => navigate('/categorydetail', { state: { categoryName: k, videoList: videoList}})}

              >{k !== null ? k : "General"}<span className="link-text"> Fedezze fel mindegyiket</span></button>  
              <div className="category-title d-block d-lg-none" 
              >{k !== null ? k : "General"}</div>  
              <CarouselComponent category={k !== null ? k : null} videoList={videoList} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
          
            </div>
            
        )})}
       
        {likeVideoList.length > 0 && (
          <div style={{position: "relative", marginTop: "15px", marginBottom: "10px"}} id={"carousel-0"}>
              <button className="category-title">Legtöbbet megtekintett videók</button>  
              <CarouselComponentTop videoList={likeVideoList} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
          </div>)}

        {categoryList.filter((k,n) => n >3).map((k, n) => {return (
          <div key={n} style={{position: "relative", marginBottom: "15px"}} id={"carousel-" + n+1}>
              <button className="category-title d-none d-lg-flex" 
                      onClick={() => navigate('/categorydetail', { state: { categoryName: k, videoList: videoList}})}

              >{k !== null ? k : "General"}<span className="link-text"> Fedezze fel mindegyiket</span></button>  
              <div className="category-title d-block d-lg-none" 
              >{k !== null ? k : "General"}</div> 
            <CarouselComponent category={k !== null ? k : null}  videoList={videoList} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
        
          </div>
        )})}
          </div>
        </section>
    </MDBContainer>


  );
};

export default  VideosPage;


// react imports
import React, { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import prev from '../../assets/media/icons/prev-green.png'
import video_clock from '../../assets/media/icons/video-clock.png'
import video_play from '../../assets/media/icons/Group 3.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled-green.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty-green.png'
import like_filled from '../../assets/media/icons/like2.png'
import like_empty from '../../assets/media/icons/like.png'

import 'swiper/css';
import AppContext from '../../AppContext';
import { MDBContainer
          } from 'mdb-react-ui-kit';

const VideosDetailPage = (props) => {

  const location = useLocation();
  const context = useContext(AppContext);

  // react router navigation
  const navigate = useNavigate();

  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isVideoHover, setIsVideoHover] = useState(false);
  const [videoDetail, setIsVideoDetail] = useState(null);

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideoHeight(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
 
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);
  useEffect(() => {
    context.interAxios.get('/video/' + window.location.pathname.split("/")[window.location.pathname.split("/").length-1], function(response) {
      console.log(response.data)
      setIsBookmarked(response.data.bookmarked)
      setIsLiked(response.data.isLiked)
      setIsVideoDetail(response.data)

    });
    setTimeout(() => {
      setIsVideoHover(true)}, 500)
  }, [window.location.pathname]);

  
const onPutVideoToBookmark = (id) => {
  context.interAxios.put('/bookmark/' + id, function(response) {
    setIsBookmarked(true)
  });
}

const onDeleteVideoFromBookmark = (id) => {
    context.interAxios.delete('/bookmark/' + id, function(response) {
      setIsBookmarked(false)
    });
}

const onPutVideoToLike = (id) => {
  context.interAxios.put('/like/' + id, function(response) {
    setIsLiked(true)
  });
}

const onDeleteVideoFromLike = (id) => {
    context.interAxios.delete('/like/' + id, function(response) {
      setIsLiked(false)
    });
}

  return (
    <>
    {videoDetail !== null && (
        <MDBContainer fluid className="videodetail-container">
          <button className="black-button" type="button" onClick={() => {
                              if(location.state.categoryName !== undefined){
                                navigate('/videos', { state: { categoryName: location.state.categoryName  } })
                              }else {
                                navigate('/videos')
                              }}}><img src={prev} alt="back"/><span>Back</span></button>
        
          <section className="first-section" id="">
            
            <div className={isVideoHover ? "reactplayer-div hover-effect" : "reactplayer-div"}>
            <iframe src={videoDetail.fullUrl + "&controls=0&autoplay=1#t=" + parseInt(videoDetail.duration/180) + "m14s&end=" + parseInt(videoDetail.duration/3 + 120) }  width={videoHeight < videoWidht*0.5625 ? videoHeight/0.5625 :  videoWidht} height={videoHeight < videoWidht*0.5625 ? videoHeight : videoWidht*0.5625 +"px"} allowFullScreen="allowFullScreen" allow="autoplay"
                  mozallowfullscreen="mozallowfullscreen" 
                  msallowfullscreen="msallowfullscreen" 
                  oallowfullscreen="oallowfullscreen" 
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
            </div>
          
          </section>
          <section className="detail-section">
            <div className="video-title">
              <span className="title-text">
                {videoDetail.name}
              </span>

              <div className="like-div">
                {videoDetail.countAllLikes > 0 && (
                  <span className="sum-like">{videoDetail.countAllLikes} embernek tetszik</span>
                )}
                <button className="like-button" type="button" onClick={isLiked ? 
                                  () => onDeleteVideoFromLike(videoDetail.id) : () => onPutVideoToLike(videoDetail.id)}>
                  <img src={isLiked ? like_filled : like_empty} alt="bookmark" />
                  <span>Tetszik</span>
                </button>
              </div>
            </div>
            <div className="timer">
                <img src={video_clock} alt="timer"/>
                <span>{parseInt(videoDetail.duration/60) + "minutes"}</span>
            </div>
            <div className="play-button">
            <button type="button" onClick={() => { navigate('/videoplay', { state: { fullUrl: videoDetail.fullUrl, categoryName: undefined} })}}><img src={video_play} alt="player"/></button>
            </div>
            <div className="video-description">
              <span>{videoDetail.description}</span>
            </div>
            
            <div className="bookmark-div">
              <button className="bookmark-button" type="button" onClick={isBookmarked ? 
                                () => onDeleteVideoFromBookmark(videoDetail.id) : () => onPutVideoToBookmark(videoDetail.id)}>
                <img src={isBookmarked ? bookmark_filled : bookmark_empty} alt="bookmark" />
              </button>
              <span>{isBookmarked ? "Töröld a kedvencek listájáról" : "Add hozzá a kedvencekhez"}</span>
            </div>
          </section>

          </MDBContainer>
    )}
    </>
   


  );
};

export default VideosDetailPage;
